<!-- anchor-serial -->
<template>
  <div class="anchor-serial">
    <!-- 查询表单 -->
    <div class="anchor-serial-form">
      <el-form
        :model="model"
        inline
        size="small"
        label-width="70px"
        @submit.native.prevent
      >
        <el-form-item label="流水日期:">
          <el-date-picker
            v-model="model.serialDateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="请选择"
            end-placeholder="请选择"
          />
        </el-form-item>

        <el-form-item label="直播平台:">
          <live-platform v-model="model.platformId" />
        </el-form-item>

        <el-form-item label="游戏名称:">
          <game-name v-model="model.gameId" />
        </el-form-item>

        <el-form-item label="流水类型:">
          <serial-type v-model="model.serialType" />
        </el-form-item>

        <el-form-item label="搜索:">
          <el-input
            v-model.trim="model.keyword"
            placeholder="主播名称、直播间号、商务"
            clearable
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="searching"
            @click="handleFormSearchClick"
            >查询
          </el-button>

          <el-button icon="el-icon-refresh" @click="handleFormResetClick"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 操作栏 -->
    <div class="anchor-serial-operate">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleOperateAddClick"
        >新增
      </el-button>

      <el-button
        type="danger"
        icon="el-icon-delete"
        size="small"
        :disabled="!removeIds.length"
        @click="handleOperateRemoveClick"
        >删除
      </el-button>
    </div>

    <!-- 数据表格 -->
    <div class="anchor-serial-table">
      <el-table
        v-loading="loading"
        ref="table"
        :data="formattedSerials"
        size="small"
        show-summary
        :summary-method="summaryMethod"
        @selection-change="handleTableSelectionChange"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column label="流水日期" prop="serialDate" />

        <el-table-column label="主播名称" prop="anchorName" />

        <el-table-column label="直播平台" prop="platformName" />

        <el-table-column label="游戏名称" prop="gameName" />

        <el-table-column label="首播时间" prop="firstShowTime" width="180px" />

        <el-table-column label="流水数" prop="income" />

        <el-table-column label="注册数" prop="registration" />

        <el-table-column label="支出数" prop="expenditure" />

        <el-table-column label="流水类型">
          <template v-slot="{ row: { serialType } }">
            <el-tag :type="serialType | serialTypeTagClass" size="mini">
              {{ serialType | serialTypeText }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="商务" prop="username" />

        <el-table-column label="创建时间" prop="createTime" width="180px" />

        <el-table-column label="操作" width="90px">
          <template v-slot="{ row }">
            <div class="table-row-operate">
              <el-button
                v-if="
                  currentUserHasFullPermissions || row.userId === currentUserId
                "
                icon="el-icon-edit"
                circle
                size="mini"
                @click="handleTableRowEditClick(row)"
              />

              <el-button
                v-if="
                  currentUserHasFullPermissions || row.userId === currentUserId
                "
                plain
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="handleTableRowRemoveClick(row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="anchor-serial-pagination">
      <el-pagination
        :total="total"
        :current-page="pageNo"
        :page-sizes="pageSizes"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageNoChange"
        @size-change="handlePageSizeChange"
      />
    </div>

    <!-- 新增/编辑流水对话框 -->
    <serial-edit
      v-model="showEditDialog"
      @on-ok="handleSerialEditDialogOkClick"
    />

    <!-- 删除流水对话框 -->
    <serial-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-close="handleSerialRemoveDialogClose"
      @on-ok="handleSerialRemoveDialogOkClick"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import LivePlatform from '../../../components/live-platform';
  import GameName from '../../../components/game-name';
  import SerialType from '../../../components/serial-type';
  import { pageSize, pageSizes } from '../../../config/pagination';
  import recountPageNo from '../../../lib/recount-page-no';
  import serialType from '../../../enum/serial-type';
  import { removeSerials } from '../../../api/anchor/serial';

  export default {
    name: 'anchor-serial',
    components: {
      LivePlatform,
      GameName,
      SerialType,
      SerialEdit: () =>
        import(/* webpackChunkName: 'serial-edit' */ './serial-edit'),
      SerialRemove: () =>
        import(
          /* webpackChunkName: 'serial-remove' */ '../../../components/confirm'
        ),
    },
    filters: {
      // 流水类型标签类名
      serialTypeTagClass(val) {
        const classMap = {
          [serialType.enum.cooperative]: 'primary',
          [serialType.enum.subsequent]: 'success',
        };

        return classMap[val];
      },
      // 流水类型文案
      serialTypeText(val) {
        return serialType.map[val] || '';
      },
    },
    data() {
      return {
        model: null,
        pageNo: 1,
        pageSize,
        pageSizes,
        operateId: '',
        showEditDialog: false,
        removeIds: [],
        showRemoveDialog: false,
        loading: false,
        searching: false,
        removing: false,
      };
    },
    computed: {
      ...mapState('anchor/serial', ['serials', 'total']),
      ...mapGetters(['currentUserId', 'currentUserHasFullPermissions']),

      // 格式化流水列表
      formattedSerials() {
        return this.serials.map(item => {
          const { expenditureCPT = 0, expenditureCPS = 0 } = item;

          return {
            ...item,
            expenditure: expenditureCPT + expenditureCPS,
          };
        });
      },
    },
    created() {
      this.initModel();
    },
    mounted() {
      this.getTableData();
    },
    beforeDestroy() {
      this.mttSerialId();
    },
    methods: {
      ...mapMutations('anchor/serial', ['mttSerialId']),
      ...mapActions('anchor/serial', ['actSerials']),

      // 初始化模型
      initModel() {
        this.model = {
          serialDateRange: [],
          platformId: '',
          gameId: '',
          serialType: '',
          keyword: '',
        };
      },
      // 计算合计的方法
      summaryMethod({ columns, data }) {
        const sums = [];

        columns.forEach((col, index) => {
          if (index === 0) {
            sums[index] = '合计';

            return;
          }

          if (index === 6 || index === 7 || index === 8) {
            const values = data.map(item => parseInt(item[col.property]) || 0);

            if (values.some(val => isNaN(val))) {
              sums[index] = 'N/A';
            } else {
              sums[index] = values.reduce((prev, curr) => prev + curr, 0);
            }

            return;
          }

          sums[index] = '-';
        });

        return sums;
      },
      // 获取表格数据
      async getTableData() {
        const { serialDateRange } = this.model;
        const [startTime = '', endTime = ''] = serialDateRange || [];

        this.loading = true;

        const success = await this.actSerials({
          ...this.model,
          startTime,
          endTime,
          serialDateRange: undefined,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });

        this.loading = false;

        return success;
      },
      // 删除表格数据
      async removeTableData() {
        const ids = this.removeIds;

        if (!ids.length) return;

        const res = await removeSerials(ids);

        return !!res;
      },
      // 表单查询按钮单击
      async handleFormSearchClick() {
        this.pageNo = 1;
        this.searching = true;

        await this.getTableData();

        this.searching = false;
      },
      // 表单重置按钮单击
      handleFormResetClick() {
        this.pageNo = 1;

        this.initModel();
        this.getTableData();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 操作栏删除按钮单击
      handleOperateRemoveClick() {
        const removeIds = this.removeIds;
        const matchedSerials = this.serials.filter(({ _id }) =>
          removeIds.includes(_id)
        );

        for (let serial of matchedSerials) {
          const { userId } = serial;

          if (userId !== this.currentUserId) {
            this.$message.error('只能删除【自己】的数据');

            return;
          }

          continue;
        }

        this.showRemoveDialog = true;
      },
      // 表格选择项改变
      handleTableSelectionChange(rows) {
        this.removeIds = rows.map(row => row._id);
      },
      // 表格行编辑按钮单击
      handleTableRowEditClick({ _id }) {
        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 表格行删除按钮单击
      handleTableRowRemoveClick({ _id }) {
        this.removeIds = [_id];
        this.showRemoveDialog = true;
      },
      // 新增/编辑流水对话框确定按钮执行成功
      handleSerialEditDialogOkClick() {
        this.getTableData();
      },
      // 删除对话框关闭
      handleSerialRemoveDialogClose() {
        this.removeIds = [];

        this.$refs.table.clearSelection();
      },
      // 删除流水对话框确定按钮单击
      async handleSerialRemoveDialogOkClick() {
        this.removing = true;

        const success = await this.removeTableData();

        this.removing = false;

        if (!success) return;

        this.showRemoveDialog = false;
        this.pageNo = recountPageNo(
          this.total,
          this.pageNo,
          this.pageSize,
          this.removeIds.length
        );

        this.getTableData();
      },
      // 分页器页码改变
      handlePageNoChange(pageNo) {
        this.pageNo = pageNo;

        this.getTableData();
      },
      // 分页器条目数改变
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;

        this.getTableData();
      },
    },
    watch: {
      operateId(n, o) {
        this.mttSerialId(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  .anchor-serial {
    // 查询表单
    .anchor-serial-form {
      max-width: 85%;

      .el-form-item {
        margin-right: 70px;
      }

      .el-input,
      .el-select,
      .el-date-editor {
        width: 220px;
      }
    }

    // 数据表格
    .anchor-serial-table {
      margin-top: 20px;

      // 操作按钮
      .table-row-operate {
        .el-button {
          min-width: auto;
        }
      }
    }

    // 分页器
    .anchor-serial-pagination {
      margin-top: 20px;
    }
  }
</style>
